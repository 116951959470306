import Bar from "./bars";

function Welcome() {
  let breadCrumb = [
    { "الرئيسية": "/welcome" }
  ];

  const createBackUp = async () => {
    let name = localStorage.getItem("username");
    let password = localStorage.getItem("password");
    try {

      // Make a request to the API
      const response = await fetch(`https://api.puzzle-technology.com/puzzle_tech_system/create-backup.php?name=${name}&password=${password}`, {
        method: 'GET',
      });

      // Check if the request was successful
      if (response.ok) {
        // Create a blob from the response
        const blob = await response.blob();

        // Create a link element to download the file
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'db-backup.sql'; // You can dynamically generate the file name based on response headers or other logic
        document.body.appendChild(link);
        link.click();

        // Clean up the link after download
        link.parentNode.removeChild(link);
      } else {
        console.error('Failed to create the backup file');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <>
      <Bar 
        pagename="welcome" 
        notification={"1"} 
        breadCrump={breadCrumb} 
        child={
          <>
            <button className="btn" onClick={createBackUp}>
              حفظ نسخة بيانات
            </button>
          </>
        } 
      />
    </>
  );
}

export default Welcome;
