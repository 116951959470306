import React, { useEffect, useState } from "react";
import "../assets/css/root.css";
import page from "../assets/css/customerinvoice.module.css";
import InvoiceInputsStyles from "../assets/css/invoiceinputs.module.css";
import { useNavigate } from 'react-router-dom';
import { JSFunctions } from "../App";
import CompleteSellPopup from "./popups/CompleteSellPopup"
import Overlay from "./overlay";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../assets/toasts/toasts";
import { Toaster } from "react-hot-toast";

function CustomerInvoice() {
  const [data, setData] = useState({
    serial: "",
    customerPrice: "",
    purchasePrice: "",
    client: "",
    warranty: "",
    manualSerial: "",
    date: ""
  });
  const [productsData,setProductsData] = useState([]);
  const [total, setTotal] = useState(0);
  const [overlay, setOverlayState] = useState(false);
  const customerInvoicePopupState = useSelector(state => state.customerInvoicePopup);
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Toast = new Toasts();


  function handleManualSerial(e){
    let condition = "Enter";
    if (!state.toggle) {
      condition = "Control"
    }
    if (e.key === condition && data.manualSerial.length !== 0 &&  data.date.length !== 0 && data.customerPrice.length !==0 && data.purchasePrice.length !== 0 && data.client.length !==  0 && data.warranty.length !== 0) {
      let foundObject = productsData.find(obj => obj.serial === data.manualSerial);
      console.log(productsData);
      if (foundObject) {
          Toast.error("هذا المنتج موجود بالفعل!")
      }else {
        let product = {
          serial: data.manualSerial,
          name: data.name,
          customerprice: data.customerPrice,
          price: data.purchasePrice,
          client: data.client,
          gurantee: data.warranty,
          discount: 0,
          date: data.date,
      }
      if (product) {
          setProductsData((prevData)=>[...prevData,product]);
          setData((prevData) => ({
              ...prevData,
              [data.manualSerial]: "",
            }));
      }else {
          alert("لم يتم العثور علي منتج بهذا الكود.");
      }
      }
            
        }
  }
  const handleChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleSerial = (e) => {
    let condition = "Enter";
    if (!state.toggle) {
      condition = "Control"
    }
    if (e.key === condition && data.serial.length !== 0) {
      addProduct();
    }

  };
  async function addProduct() {
    let foundObject = productsData.find(obj => obj.serial === data.serial);
    if (foundObject) {
        Toast.error("هذا المنتج موجود بالفعل!")
    }else {
      let functions = new JSFunctions();
      setOverlayState(true);
      let product = await functions.searchByCode(data.serial,1);
      setOverlayState(false);
      if (!product) {
        Toast.error("منتج غير موجود.");
      }else {
        product["discount"] = 0;
        setProductsData((prevData)=>[...prevData,product])
        Toast.success("تم الإضافة بنجاح.");
        handleChange("serial", "")
      }
    }
  }
  function handleDiscountChange(serial, val) {
    setProductsData((prevProductsData) => {
      const updatedProductsData = [...prevProductsData];
      const index = updatedProductsData.findIndex((item) => item.serial === serial);
  
      if (index !== -1) {
        // Update the discount for the specific product
        updatedProductsData[index] = {
          ...updatedProductsData[index],
          discount: val,
        };
      }
  
      return updatedProductsData;
    });
  }
  function handleDelete(serial) {
    setProductsData((prevTableProducts) =>
        prevTableProducts.filter((item) => item.serial !== serial
    )) 
  }
  function handleTable() {
    return productsData.map((product,index)=>{
        return <tr key={product["serial"]}>
    <td>{index+1}</td>
    <td>{product["name"]}</td>
    <td>{product["serial"]}</td>
    <td>{product["customerprice"]}</td>
    <td>{product["gurantee"]}</td>
    <td>
      <input
        onChange={(val) =>
          handleDiscountChange(product["serial"], +val.target.value)
        }
        type="number"
      ></input>
    </td>
    <button onClick={()=>handleDelete(product["serial"])} className={page["del"]}>حذف</button>
  </tr>
    })
  }
  function PrintDocument() {
    dispatch({type: "SET_PRODUCTS", payload: {products: productsData}})
    console.log(state.productsData)
    navigate("/sellpoints/customer-invoice/invoice")
  }
  function handleFinishSell(){
    if (productsData.length === 0) {
      Toast.error("يجب إضافة منتجات فالسلة أولا.");
    }else {
      dispatch({type: "SET_INVOICE_DATA", payload:  {
        products: productsData,
        total: total,
        isCustomer: 1,
        customerInvoicePopup: true
      }})
    }
  }
  function calculateTotal() {
    let newTotal = 0;
    productsData.forEach((ele) => {
      newTotal += +ele["customerprice"] - ele["discount"];
    });
    setTotal(newTotal);
  }
  function handleCancle(){
    navigate("/sellpoints/");
  }
  useEffect(() => {
    console.log(state)
    calculateTotal();
  }, [productsData,state]);
  // Check if there saved invoice in the  storage, if ? delete it 
  useEffect(()=>{
    console.log(state["customerName"])
    if(state["customerName"].length > 0) {
      console.log('hasfasf')
      dispatch({type: "restart"});
    }
  },[])
  return <div className={page["customer-invoice"]}>
          <h1 className="title">فاتورة مستهلك</h1>
          <div className={InvoiceInputsStyles["invoice-inputs"]}>
            <input
              className={`${InvoiceInputsStyles["serial"]} ${InvoiceInputsStyles["invoice-input"]}`}
              placeholder="الكود"
              value={data.serial}
              onChange={(e) => handleChange("serial", e.target.value.trim())}
              onKeyDown={handleSerial}
            />
            
            <div className={InvoiceInputsStyles["del-btn"]} onClick={()=>handleCancle()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M12.2267 19.7732L19.7734 12.2266"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.7734 19.7732L12.2267 12.2266"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 29.3334H20C26.6666 29.3334 29.3333 26.6667 29.3333 20.0001V12.0001C29.3333 5.33341 26.6666 2.66675 20 2.66675H12C5.33329 2.66675 2.66663 5.33341 2.66663 12.0001V20.0001C2.66663 26.6667 5.33329 29.3334 12 29.3334Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>إالغاء الفاتورة</span>
            </div>
            <input
              className={InvoiceInputsStyles["invoice-input"]}
              placeholder="الإسم"
              value={data.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
            <input
              className={InvoiceInputsStyles["invoice-input"]}
              placeholder="سعر المستهلك"
              value={data.customerPrice}
            type="number"

              onChange={(e) => handleChange("customerPrice", e.target.value)}
            />
            <input
            type="number"
              className={InvoiceInputsStyles["invoice-input"]}
              placeholder="سعر الشراء"
              value={data.purchasePrice}
              onChange={(e) => handleChange("purchasePrice", e.target.value)}
            />
            <input
              className={InvoiceInputsStyles["invoice-input"]}
              placeholder="الموكل"
              value={data.client}
              onChange={(e) => handleChange("client", e.target.value)}
            />
            <input
              className={InvoiceInputsStyles["invoice-input"]}
              placeholder="الضمان"
              value={data.warranty}
              onChange={(e) => handleChange("warranty", e.target.value)}
            />
            <input
              className={InvoiceInputsStyles["invoice-input"]}
              type="date"
              value={data.date}
              onChange={(e) => handleChange("date", e.target.value)}
            />
            <input
              className={InvoiceInputsStyles["invoice-input"]}
              placeholder="الكود"
              value={data.manualSerial}
              onChange={(e) => handleChange("manualSerial", e.target.value)}
              onKeyDown={handleManualSerial}
            />
        <select
          style={{direction: "ltr"}}
            className={InvoiceInputsStyles["invoice-input"]}
            value={data.category}
            onChange={(ele) => {
              console.log(ele.target.value)
              setData({ ...data, category: ele.target.value });
            }}
            defaultValue={"CPU"}
          >
   
          <option selected value={"CPU"}>CPU</option>
          <option value={"GPU"}>GPU</option>
          <option value={"Motherboard"}>Motherboard</option>
          <option value={"RAM"}>RAM</option>
          <option value={"Storage"}>Storage</option>
          <option value={"CPU Cooler"}>CPU Cooler</option>
          <option value={"Case + PSU"}>Case + PSU</option>
          <option value={"Case"}>Case</option>
          <option value={"PSU"}>PSU</option>
          <option value={"Monitor"}>Monitor</option>

          <optgroup label="Accessories">
            <option value="">Thermal Paste</option>
            <option value="">GPU Holder</option>
            <option value=""></option>
            <option value=""></option>
            <option value=""></option>

            <option value="Headphones">Headphones</option>
            <option value="Mouses">Mouses </option>
            <option value="Keyboards">Keyboards </option>
            <option value="Keyboards">Keyboard + Mouse </option>
            <option value="Keyboards">Keyboard + Headphones </option>
            <option value="Keyboards">Mouse + Headphones </option>
          </optgroup>

          <optgroup label="Storage">
            <option value="HDD">HDD</option>
            <option value="SSD">SSD </option>
            <option value="Nvme">NVME </option>
          </optgroup>
          </select>

            <input
              className={`total ${InvoiceInputsStyles["invoice-input"]}`}
              placeholder="الإجمالي"
              value={"الإجمالي: " + total}
            />
    
            <Toaster
              position="top-left"
              reverseOrder={false}
            />
          </div>
          <div className={InvoiceInputsStyles["actions"]}>
              <div className={InvoiceInputsStyles["complete-sell"]} onClick={()=>handleFinishSell()}>إتمام الشراء</div>
              <div className={InvoiceInputsStyles["print"]} onClick={()=>{PrintDocument()}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 33 32"
                  fill="none"
                >
                  <path
                    d="M10.1666 9.33341H22.8333V6.66675C22.8333 4.00008 21.8333 2.66675 18.8333 2.66675H14.1666C11.1666 2.66675 10.1666 4.00008 10.1666 6.66675V9.33341Z"
                    stroke="#405189"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.8333 20V25.3333C21.8333 28 20.5 29.3333 17.8333 29.3333H15.1666C12.5 29.3333 11.1666 28 11.1666 25.3333V20H21.8333Z"
                    stroke="#405189"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.5 13.3333V19.9999C28.5 22.6666 27.1667 23.9999 24.5 23.9999H21.8333V19.9999H11.1667V23.9999H8.5C5.83333 23.9999 4.5 22.6666 4.5 19.9999V13.3333C4.5 10.6666 5.83333 9.33325 8.5 9.33325H24.5C27.1667 9.33325 28.5 10.6666 28.5 13.3333Z"
                    stroke="#405189"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.1667 20H21.5534H9.83337"
                    stroke="#405189"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.83337 14.6667H13.8334"
                    stroke="#405189"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>طباعة فاتورة عرض سعر</span>
              </div>
            </div>
          <table>
            <thead>
              <tr>
                <td>التسلسل</td>
                <td>الإسم</td>
                <td>الكود</td>
                <td>السعر</td>
                <td>الضمان</td>
                <td>الخصم</td>
              </tr>
            </thead>
            <tbody>{handleTable()}</tbody>
          </table>
          {
            customerInvoicePopupState || overlay? <Overlay></Overlay> :null
          }
          {
            customerInvoicePopupState? <CompleteSellPopup></CompleteSellPopup> :null
          }
        </div>
}

export default CustomerInvoice;
